import React, { useState, useEffect } from 'react';
import { Upload, message, Button, Table } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { getBoardingRates, uploadFile } from 'src/redux/boardingrates';
import { getLocations, selectLocationIds } from 'src/redux/location';
import { useDispatch, useSelector } from 'react-redux';
import { selectBoardingRates } from 'src/redux/boardingrates';
import _ from 'lodash';

const BoardingRates = () => {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);
	const [ratesFiles, setRatesFiles] = useState([]);
	const [tableData, setTableData] = useState(null);
	const [tableColumns, setTableColumns] = useState(null);
	const boardingRates = useSelector(selectBoardingRates);
	const locationIds = useSelector(selectLocationIds);

	useEffect(() => {
		dispatch(getBoardingRates());
		dispatch(getLocations());
	}, []);

	useEffect(() => {
		const tempColumns = [
			{
				title: 'Location ID',
				dataIndex: 'locationId',
				key: 'locationId',
				sorter: (a, b) => a.locationId - b.locationId,
				filters: locationIds.map((item) => {
					return { text: item, value: item };
				}),
				onFilter: (value, record) => record.locationId === value,
			},
			{
				title: 'Start Date',
				dataIndex: 'startDate',
				key: 'startDate',
				render: (text) => <span>{text ? text.substring(0, 10) : '--'}</span>,
			},
			{
				title: 'End Date',
				dataIndex: 'endDate',
				key: 'endDate',
				render: (text) => <span>{text ? text.substring(0, 10) : '--'}</span>,
			},
			{
				title: 'Additional Rate',
				dataIndex: 'additionalRate',
				key: 'additionalRate',
				render: (text) => <span>+${text}</span>,
			},
			{
				title: 'Rate Type',
				dataIndex: 'rateType',
				key: 'rateType',
			},
		];

		setTableColumns(tempColumns);
	}, [locationIds]);

	useEffect(() => {
		const data = _.sortBy(boardingRates.dates ?? [], 'locationId');
		setTableData(data);
	}, [boardingRates]);

	const handleUpload = async (file) => {
		const formData = new FormData();
		formData.append('file', file);
		setLoading(true);
		await dispatch(uploadFile(formData));
		setLoading(false);
		message.success(`File uploaded successfully.`);
	};

	const props = {
		name: 'rates',
		multiple: false,
		listType: 'text',
		accept: '.csv',
		maxCount: 1,
		showRemoveIcon: false,
		showUploadList: {
			showRemoveIcon: false,
		},
		fileList: ratesFiles,
		beforeUpload: async (file) => {
			handleUpload(file);
			setRatesFiles([file]);
			return false;
		},
	};

	return (
		<div
			style={{
				width: '100%',
				backgroundColor: '#fff',
				borderRadius: 10,
				padding: 16,
			}}>
			<h1>Boarding Rates Settings</h1>

			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					marginTop: 16,
				}}>
				<h1
					style={{
						paddingTop: 4,
						paddingRight: 6,
					}}>
					Boarding Rates File:
				</h1>

				<Upload {...props}>
					<Button icon={<UploadOutlined />} loading={loading}>
						Upload CSV
					</Button>
				</Upload>

				<div style={{ marginTop: 16 }}>
					{tableData && (
						<Table
							dataSource={tableData}
							columns={tableColumns}
							pagination={true}
							showSorterTooltip={{
								target: 'sorter-icon',
							}}
						/>
					)}
				</div>
			</div>
		</div>
	);
};

export default BoardingRates;
