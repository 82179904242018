import Network from './Network';

class BoardingRatesService {
	static async get() {
		let response = await Network.request({
			method: 'GET',
			url: '/admin/additionalRatePricingSchedule',
		});
		return response.data;
	}

	static async uploadFile(payload) {
		let response = await Network.request({
			method: 'PUT',
			url: '/admin/additionalRatePricingSchedule',
			data: payload,
		});
		return response.data;
	}
}

export default BoardingRatesService;
