import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import BoardingRatesService from '../services/BoardingRates';

export const getBoardingRates = createAsyncThunk(
	'boardingrates/get',
	async (_, { dispatch, rejectWithValue }) => {
		try {
			const result = await BoardingRatesService.get();

			dispatch({
				type: 'boardingrates/setBoardingRates',
				payload: result,
			});

			return result;
		} catch (error) {
			rejectWithValue(error);
		}
	},
);

export const uploadFile = createAsyncThunk(
	'boardingrates/uploadFile',
	async (payload, { dispatch, rejectWithValue }) => {
		try {
			const result = await BoardingRatesService.uploadFile(payload);

			await dispatch(getBoardingRates());
			return result;
		} catch (error) {
			rejectWithValue(error);
		}
	},
);

export const boardingRatesSlice = createSlice({
	name: 'boardingrates',
	initialState: {
		boardingRates: [],
	},
	reducers: {
		setBoardingRates(state, action) {
			state.boardingRates = action.payload;
		},
	},
});

export const { setBoardingRates } = boardingRatesSlice.actions;

export default boardingRatesSlice.reducer;

export const selectBoardingRates = (state) =>
	state.boardingrates.boardingRates || {};
