import { configureStore } from '@reduxjs/toolkit';
import {
	persistStore,
	persistReducer,
	FLUSH,
	REHYDRATE,
	PAUSE,
	PERSIST,
	PURGE,
	REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';

import locationReducer from './location';
import nonCompatReducer from './noncompat';
import minstayReducer from './minstay';
import pictureweekReducer from './pictureweek';
import boardingratesReducer from './boardingrates';

const rootReducer = combineReducers({
	locations: locationReducer,
	noncompats: nonCompatReducer,
	minstays: minstayReducer,
	pictureweek: pictureweekReducer,
	boardingrates: boardingratesReducer,
});

const persistConfig = {
	key: 'root',
	version: 3,
	storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
				ignoredActionPaths: [],
			},
		}),
});

export const persistor = persistStore(store);
